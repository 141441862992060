import {
  attributesToProps,
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
  Text,
} from "html-react-parser";
import { ElementType } from "htmlparser2";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const useParserOptions = (): HTMLReactParserOptions => {
  const navigate = useNavigate();

  const handleLinkClicked = useCallback((
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    href: string
  ) => {
    event.preventDefault();
    navigate(href);
  }, [navigate]);

  const options = useMemo(() => {
    const replace = (domNode: DOMNode) => {
      if (domNode instanceof Element) {
        const elementNode = domNode as Element;
        if (
          elementNode.name === "a" &&
          elementNode.attribs.href &&
          !elementNode.attribs.href.startsWith("http") &&
          !elementNode.attribs.href.startsWith("#") 
        ) {
          const attribs = { ...elementNode.attribs, style: {} } as any;
          return (
            <a
              {...attribs}
              onClick={(event) => {
                handleLinkClicked(event, elementNode.attribs.href);
              }}
            >
              {domToReact(elementNode.children, options)}
            </a>
          );
        } else if (
          elementNode.name === "table" &&
          (elementNode.attribs.class === "wikitable" ||
            elementNode.attribs.class?.includes("infobox") ||
            elementNode.attribs.class?.includes("navbox") ||
            (elementNode.attribs.class === undefined &&
              !elementNode.attribs.style?.includes("width:100%")))
        ) {
          return (
            <div className="scrollable-container">
              {/* styleはすべて捨てる */}
              <table className={elementNode.attribs.class}>
                {domToReact(elementNode.children, options)}
              </table>
            </div>
          );
        } else if (
          elementNode.name === "div" &&
            elementNode.attribs.class?.includes("navbox")
        ) {
          const attribs = { ...elementNode.attribs, style: {} } as any;
          // styleはすべて捨てる
          return (
            <div {...attribs}>
              {domToReact(elementNode.children, options)}
            </div>
          );
        } else if (
          elementNode.name === "table" && elementNode.attribs.style?.includes("width")
        ) {
          const attribs = { ...elementNode.attribs, style: {} } as any;
          return (
              <table {...attribs}>
                {domToReact(elementNode.children, options)}
              </table>
          );
        } else if (
          (elementNode.name === "th" || elementNode.name === "td") && elementNode.attribs.style?.includes("width")
        ) {
          const style = elementNode.attribs.style.replace(/width:[0-9]+%/, '');
          const attribs = { ...elementNode.attribs, style } as any;
          const props = attributesToProps(attribs);
          if (elementNode.name === 'th') {
            return (
              <th {...props}>
                {domToReact(elementNode.children, options)}
              </th>
            );
          } else {
            return (
              <td {...props}>
                {domToReact(elementNode.children, options)}
              </td>
            );

          }
        } else if (
          elementNode.name === "div" &&
          elementNode.attribs.class === "thumbinner"
        ) {
          return (
            // styleはすべて捨てる
            <div className="thumbinner">
              {domToReact(elementNode.children, options)}
            </div>
          );

          // } else if (elementNode.name === 'div' && elementNode.attribs.class === 'noresize') {
          //   return (
          //     <div className="noresize">
          //       { domToReact(elementNode.children, options) }
          //     </div>
          //   );
        }
      } else if (domNode.type === ElementType.Text) {
        // none
        // const textNode = domNode as Text;
      }
    };
    return { replace };
  }, [handleLinkClicked]);

  return options;
};
