import axios from "axios";
import { useEffect, useState } from "react";

export const useSearch = (query: string) => {
    const [searchResults, setSearchResult] = useState<any[]>([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (query === '') {
            setLoading(false);
            setSearchResult([]);
            return;
        }
        const search = () => {
            axios.get(`https://ja.wikipedia.org/w/api.php?format=json&action=query&list=search&srsearch=${encodeURIComponent(query)}&origin=*`,{
                headers: {
                }
            })
            .then((res) => {
                setSearchResult(res.data.query?.search || []);
            }).finally(() => {
                setLoading(false);
            });
        }
        setLoading(true);
        const delayDebounceFn = setTimeout(search, 200);
        return () => clearTimeout(delayDebounceFn);
    }, [query]);

    return {
        searchResults,
        isLoadingSearch: isLoading,
    }
};