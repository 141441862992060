import axios from "axios";
import { useEffect, useState } from "react";
import { API_END_POINT } from "./api";

export type ContentHtml = {
    html: string,
    title: string,
}

const cache: { [pageName: string]: ContentHtml } = {};

export function useWikipediaContent(pageName: string | undefined) {
    const [content, setContent] = useState<ContentHtml>(cache[pageName || ''] || {
        title: pageName || '',
        html: '',
    });
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (!pageName) {
            return;
        }
        const cachedContent = cache[pageName || ''];
        if (cachedContent) {
            setContent(cachedContent);
            return;
        }
        setContent({
            title: pageName,
            html: '',
        })
        setLoading(true);
        axios.get(`${API_END_POINT}/query?query=${encodeURIComponent(pageName)}`,{
            headers: {
            }
        })
        .then((res) => {
            const html = res.data.html as string;
            const contentHtml = {
                title: pageName,
                html: html,
            };
            cache[pageName] = contentHtml;
            setContent(contentHtml);
        }).finally(() => {
            setLoading(false);
        });
    }, [pageName]);

    return {
        content,
        setContent,
        isLoadingContent: isLoading,
    }
}