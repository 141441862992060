/** @jsxImportSource @emotion/react */

import { css, jsx } from '@emotion/react';
import { SearchBox } from "./SeachBox";

const styles = {
    header: css({
        display: 'flex',
        alignItems: 'center',
        height: '37px',
        padding: '12px',
        borderBottom: 'solid 1px #ddd',
        'a': css({
            textDecoration: 'none',
            color: 'inherit', 
        }),
    }),
    logoTitle: css({
        marginTop: '3px',
        marginLeft: '8px',
        marginRight: '8px',
        display: 'inline-block',
        fontSize: '1.5em',
        verticalAlign: 'middle',
        fontWeight: 'bold',
    }),
    logoText: {
        '@media only screen and (max-width: 768px)': css({
            display: 'none',
        }),
    }
};

export const Header = () => {
    return (
        <header css={styles.header}>
            <a href="/">
                <img src="/img/logo.png" height={35} style={{verticalAlign: 'middle'}} alt="ロゴ" />
            </a>
            <a css={styles.logoText} href="/">
                <span css={styles.logoTitle}>こどもペディア</span>
            </a>
            <SearchBox />
        </header>
    )
}