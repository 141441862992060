import parse from "html-react-parser";
import React, { useMemo } from "react";
import Loader from "react-loader-spinner";
import { useParams } from "react-router";
import { useWikipediaContent } from "../api/use-wikipedia";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { useParserOptions } from "../wikipedia/converter";

export function WikiPage() {
  const { pageName } = useParams();
  const { content, isLoadingContent } = useWikipediaContent(pageName);
  const options = useParserOptions();

  const wikiContent = useMemo(() => {
    console.log("content", content);
    return <>{parse(content.html, options)}</>;
  }, [content, options]);

  const sourceUrl = useMemo(() => {
    const name = content.title || pageName;
    if (!name) {
      return 'https://ja.wikipedia.org/';
    }
    return `https://ja.wikipedia.org/wiki/${encodeURIComponent(name)}`;
  }, [content.title, pageName]);

  return (
    <>
      <Header />
      <article>
        <h1>{content.title || pageName}</h1>
        {isLoadingContent ? (
          <>
            <div style={{textAlign: 'center', paddingTop: '50px'}}>
              <div style={{ display: "inline-block", verticalAlign: "middle" }}>
                <Loader type="Grid" color="#FFD641" height={25} width={25} />
              </div>
              <div style={{ display: "inline-block", verticalAlign: "middle", marginLeft: '10px' }}>読込中...</div>
            </div>
          </>
        ) : null}
        {wikiContent}
        {
          isLoadingContent ? null : <Footer sourceUrl={sourceUrl} />
        }
      </article>
    </>
  );
}
