import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { SearchBox } from "../components/SeachBox";

const pages = [
  "太陽系",
  "サイフォン",
  "マインクラフト",
  "元素",
  "誕生石",
  "Scratch",
  "パラリンピック",
  "鬼滅の刃",
];

export function RootPage() {
  return (
    <>
      <Header />
      <article>
        <ul>
          {pages.map((page) => {
            return (
              <li>
                <Link to={`/wiki/${encodeURIComponent(page)}`}>{page}</Link>
              </li>
            );
          })}
        </ul>
        <Footer sourceUrl={'https://ja.wikipedia.org/'} />
      </article>
    </>
  );
}
