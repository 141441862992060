/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import striptags from "striptags";
import { useSearch } from "../api/use-search";

const styles = {
  container: css({
    flex: 1,
    display: "flex",
    position: "relative",
    marginTop: "5px",
  }),
  form: css({
    flex: 1,
    display: "flex",
    fontSize: "20px",
    width: "200px",
    padding: "5px",
  }),
  input: css({
    flex: 1,
    fontSize: "20px",
    width: "200px",
    padding: "5px",
    borderRadius: "5px",
    border: "solid 1px #222",
  }),
  button: css({
    backgroundColor: "#ddd",
    marginLeft: "10px",
    paddingLeft: "15px",
    paddingRight: "15px",
    fontSize: "15px",
    borderRadius: "5px",
    border: "solid 1px #222",
    verticalAlign: "middle",
  }),
  suggestion: css({
    position: "absolute",
    background: "#fff",
    border: "solid 1px #777",
    top: "39px",
    boxShadow: "2px 2px 4px gray",
    zIndex: 1,
    listStyle: "none",
    padding: "0px 0px",
    margin: "0px 0px",
    fontSize: "1em",
    'li': {
      padding: '10px 10px',
      ':hover': {
        backgroundColor: '#f8f8f8',
      },
    },
  }),
  snippet: css({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "300px",
    fontSize: "0.7em",
    color: "#777",
  }),
};

export const SearchBox = () => {
  const navigate = useNavigate();
  const [typing, setTyping] = useState(false);
  const [visibleResults, setVisibleResults] = useState(false);
  const [keyword, setKeyword] = useState("");
  const { searchResults } = useSearch(keyword);
  const containserRef = useRef(null);

  useEffect(() => {
    const hideSearchResultsHandler = (ev: any) => {
      const isBodyClicked = ev.path.find((el: any) => el === containserRef.current) === undefined;
      if(isBodyClicked) {
        setVisibleResults(false);
      }
    };
    const clickEventType = window.ontouchstart ? 'touchend' : 'click';
    window.addEventListener(clickEventType, hideSearchResultsHandler)
    return () => window.removeEventListener(clickEventType, hideSearchResultsHandler);
  }, []);

  const clearAndHide = useCallback(() => {
    setKeyword('');
    setVisibleResults(false);
  }, [setKeyword, setVisibleResults]);

  const handleSubmitted = useCallback(() => {
    setVisibleResults(false);
    navigate(`/wiki/${encodeURIComponent(keyword)}`);
  }, [navigate, keyword]);

  const handleKeywordChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (typing) {
        return;
      }
      const value = e.target.value;
      setKeyword(value);
    },
    [typing]
  );

  const handleCompositionStart = useCallback(
    (e: React.CompositionEvent<HTMLInputElement>) => {
      setTyping(true);
    },
    []
  );

  const handleCompositionEnd = useCallback(
    (e: React.CompositionEvent<HTMLInputElement>) => {
      setTyping(false);
      const value = (e.target as any).value;
      setKeyword(value);
    },
    []
  );

  return (
    <div css={styles.container} ref={containserRef}>
      <form css={styles.form} onSubmit={handleSubmitted}>
        <input
          css={styles.input}
          type="text"
          placeholder="キーワード"
          onFocus={() => setVisibleResults(true)}
          onChange={handleKeywordChanged}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
        />
        {/* <button css={styles.button}>検索</button> */}
      </form>
      {visibleResults && searchResults.length > 0 ? (
        <ul css={styles.suggestion}>
          {searchResults.map((result) => {
            return (
              <Link to={`/wiki/${encodeURIComponent(result.title)}`} onClick={clearAndHide}>
                <li key={result.pageid}>
                  {result.title}
                  <div css={styles.snippet}>{striptags(result.snippet)}</div>
                </li>
              </Link>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};
