export const Footer = (props: {
    sourceUrl: string,
}): JSX.Element => {
    const { sourceUrl } = props;    return (
        <footer>
            <p>
                コンテンツは、<ruby><rp>(</rp>特<rt>とく</rt><rp>)</rp></ruby>に<ruby><rp>(</rp>記載<rt>きさい</rt><rp>)</rp></ruby>されていない<ruby><rp>(</rp>限<rt>かぎ</rt><rp>)</rp></ruby>り、<a href="https://creativecommons.org/licenses/by-sa/3.0/" target="_blank" rel="noreferrer">CC BY-SA 3.0</a>のもとで<ruby><rp>(</rp>利用可能<rt>りようかのう</rt><rp>)</rp></ruby>です。<br />
                <ruby>出典<rp>(</rp><rt>しゅってん</rt><rp>)</rp></ruby>: フリー<ruby>百科事典<rt>ひゃっかじてん</rt></ruby>『<a href={sourceUrl} target="_blank" rel="noreferrer">ウィキペディア（Wikipedia）</a>』
            </p>
            <p>ご意見ご要望は<a href="https://twitter.com/agata" target="_blank" rel="noreferrer">@agata</a>まで</p>
        </footer>
    )
}