import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RootPage } from "./pages/RootPage";
import { WikiPage } from "./pages/WikiPage";
import './App.scss';

function App() {
  const root = (() => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RootPage />} />
          <Route path="wiki">
            <Route path=":pageName" element={<WikiPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  })();

  return root;
}

export default App;
